(function (w, d) {

  gsap.registerPlugin(ScrambleTextPlugin, MorphSVGPlugin, ScrollTrigger)

  const nav = new MetisMenu('.metismenu', {
    toggle: true,
  });

  function checkMobileNavListeners() {

    const el = d.getElementById('mobileNavToggle'),
      triggers = d.getElementsByClassName('hasMenu'),
      mobileTriggers = d.querySelectorAll('.hasMenu svg');

    for (var i = 0; i < triggers.length; i++) {
      triggers[i].removeEventListener('mouseenter', opensub);
      triggers[i].removeEventListener('mouseleave', closesub);
    }
    el.removeEventListener('click', mobileNavOpener);
    for (var i = 0; i < mobileTriggers.length; i++) {
      mobileTriggers[i].removeEventListener('click', toggleSub);
    }

    if (w.outerWidth < 992) {
      el.addEventListener('click', mobileNavOpener);
      for (var i = 0; i < triggers.length; i++) {
        triggers[i].addEventListener('click', toggleSub);
      }
      for (var i = 0; i < mobileTriggers.length; i++) {
        mobileTriggers[i].addEventListener('click', toggleSub);
      }
    } else {
      for (var i = 0; i < triggers.length; i++) {
        triggers[i].addEventListener('mouseenter', opensub);
        triggers[i].addEventListener('mouseleave', closesub);
      }
    }

    function opensub(e) {

      if (w.outerWidth < 992) return;

      if (e.target === this && !e.target.classList.contains('mm-active')) {
        w.requestAnimationFrame(function () {
          show(e.target);
        });
      }

    }

    function closesub(e) {

      if (w.outerWidth < 992) return;

      w.requestAnimationFrame(function () {
        close(e.target);
      });

    }

    function toggleSub(e) {

      e.preventDefault();

      const menu = e.target.closest('.hasMenu');

      if (menu.classList.contains('mm-active') && !e.target.closest('svg') && e.target.closest('a')) {
        const url = e.target.closest('a').getAttribute('href');
        window.location.assign(url);
      } else if (menu.classList.contains('mm-active')) {
        w.requestAnimationFrame(function () {
          close(menu);
        });
      } else {
        w.requestAnimationFrame(function () {
          show(menu);
        });
      }

    }

    function show(target) {
      const subMenu = target.getElementsByTagName('ul');
      nav.show(subMenu[0]);
    }

    function close(target) {
      const subMenu = target.getElementsByTagName('ul');
      nav.hide(subMenu[0]);
    }

  }

  function mobileNavOpener() {
    d.documentElement.classList.toggle('open');
  }

  /**
   * Fame Custom In-View Animations
   * Dependencies: GSAP v3
   **/

  function initFameAnimations() {
    /**
     * To add an animation to an element add the class fameAnimate. by default it will have the fadeFromLeft animation
     * To customize it use data-option attributes. such as data-famespeed="3" to make the animation take 3 seconds instead of 1.
     * @type {HTMLCollectionOf<Element>}
     */

    const list = gsap.utils.toArray('.fameAnimate'),

      defaults = {
        fametype: "fadeFromLeft",
        famespeed: 1,
        fameintensity: 1,
        famedelay: 0,
        famereverse: 'false'
      },

      animationTypes = {
        'fadeFromLeft': [
          {x: -200, autoAlpha: 0},
          {x: 0, autoAlpha: 1}
        ],
        'fadeFromRight': [
          {x: 200, autoAlpha: 0},
          {x: 0, autoAlpha: 1}
        ],
        'fadeFromBottom': [
          {y: 200, autoAlpha: 0},
          {y: 0, autoAlpha: 1}
        ],
        'fadeFromTop': [
          {y: -200, autoAlpha: 0},
          {y: 0, autoAlpha: 1}
        ]
      }
    ;

    list.forEach(element => {

      const options = Object.assign({}, defaults, element.dataset);

      const toValues = Object.assign(
        {},
        {
          delay: options.famedelay,
          duration: options.famespeed
        },
        animationTypes[options.fametype][1])
      ;

      const animation = gsap.fromTo(element, animationTypes[options.fametype][0], toValues);

      ScrollTrigger.create({
        animation: animation,
        trigger: element,
        start: "top bottom",
        once: options.famereverse === 'false'
      })

    })

  }

  function initParallax() {
    if (d.getElementsByClassName('parallax').length > 0) {
      const parallax = new Rellax('.parallax', {
        speed: -1
      })
    }
  }

  function adjustNearbyNow(mutationsList, observer) {

    function isEmpty(el) {
      return el.innerHTML.trim().length <= 0
    }

    const nnLeft = d.querySelector(".nn-combo-left"),
      nnRight = d.querySelector(".nn-combo-right");

    if (isEmpty(nnLeft)) {
      nnRight.classList.add('fullWidth');
      nnLeft.classList.add('empty');
    } else if (isEmpty(nnRight)) {
      nnLeft.classList.add('fullWidth');
      nnRight.classList.add('empty');
    }

    if (observer !== null) {
      observer.disconnect();
    }

  }

  const nnContainer = d.querySelector('.nn-container');
  if (nnContainer) {
    if (d.querySelector('.nn-review-outer-cont')) {
      adjustNearbyNow(null, null);
    } else {
      const nnObserver = new MutationObserver(adjustNearbyNow);
      const nnObserverConfig = {attributes: false, childList: true, subtree: false}
      nnObserver.observe(nnContainer, nnObserverConfig);
    }
  }

  function updateCopyrightYear() {
    document.getElementById("copyright-year").textContent = String(new Date().getFullYear());
  }

  w.addEventListener('load', () => {
    checkMobileNavListeners()
    initFameAnimations()
    initParallax()
    updateCopyrightYear()
  })

  w.onresize = function () {

    if (typeof navUpdate === 'undefined') {
      let navUpdate = w.requestAnimationFrame(function () {
        nav.dispose();
        nav.update();
        checkMobileNavListeners();
      });
    } else {
      w.cancelAnimationFrame(navUpdate)
    }

  }

})(window, document);
